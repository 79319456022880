import { onSlotRendered } from './modules/monetization';
import MonetizationManager from './modules/monetization/monetization-manager';
import PrebidAdapter from './modules/monetization/prebid-adapter';
import AmazonAdapter from './modules/monetization/amazon-adapter';
import AdSlot from './modules/monetization/ad-slot';
import SharedConfig from './modules/monetization/shared-config';
import { getResponsiveSizes, sizes } from './modules/monetization/config';
import { getNestedText } from './services/article/get-nested-text';
import { getPolicyViolatedWords } from './modules/monetization/get-policy-violated-words';
import { filterBiddersByGeo } from './modules/monetization/filter-bidders-by-geo';
import { getGeolocation } from './services/get-geolocation';
import {
  extractIiqId,
  getIntentIqTestGroup,
  intentIqTagInit,
  isIntentIqEnabled,
} from './modules/intent-iq';
import { isCurrentArticle } from './services/article/is-current-article';

const $anchorAdvCloseButton = document.querySelector(`.js-adv-close-btn`);
const $articleContent = document.querySelector(`.js-article-body`);

export const associateArray = (array, propName) =>
  array.reduce((object, item) => {
    object[item[propName]] = item;
    return object;
  }, {});

if ($anchorAdvCloseButton) {
  $anchorAdvCloseButton.addEventListener(`click`, (event) => {
    event.target.parentNode.classList.toggle(`c-adv-anchor--collapsed`);
  });
}

const gptAdSlotConfigs = associateArray(window.Site.slots || [], `code`);
const prebidAdSlotConfigs = associateArray(window.Site.prebidUnits || [], `code`);
let targeting = window.Site.targeting || {};
const responsiveSizes = getResponsiveSizes(sizes);

if ($articleContent) {
  targeting.violated_words = getPolicyViolatedWords(getNestedText($articleContent));
}

export const monetizationManager = new MonetizationManager([
  // Anchor
  `brieflyrsa_universal_anchor`,
  `legit_anchor`,
  `kami_universal_anchor`,
  `tuko_universal_anchor`,

  // Sidebar sticky
  `legit_sidebar_bottom`,
  `tuko_09`,
  `briefly_05`,
  `kami_08`,

  // For All Yen project
  `yen`,

  // Profit Button on Briefly
  `briefly_1x1_pixel`,
]);

if (!isCurrentArticle(window.Site.postsWithoutAds)) {
  getGeolocation().then((geo) => {
    const intentIqStatus = isIntentIqEnabled(geo);

    window.adSharedConfigs = Object.values(gptAdSlotConfigs).map((config) => {
      const filteredPrebidConfigs = filterBiddersByGeo(geo, prebidAdSlotConfigs[config.code]);

      const sharedConfig = new SharedConfig({ ...config }, filteredPrebidConfigs);

      sharedConfig.setResponsiveSizes(responsiveSizes);

      return sharedConfig;
    });

    window.adSharedConfigs = associateArray(window.adSharedConfigs, `sharedId`);

    const uniqueConfigs = Object.values(window.adSharedConfigs).map((sharedConfig) =>
      sharedConfig.getUniqueConfig(),
    );

    monetizationManager.addSlots(uniqueConfigs.map((config) => new AdSlot(config)));

    if (intentIqStatus) {
      const intentIqTag = intentIqTagInit();

      targeting = { ...targeting, ...getIntentIqTestGroup(intentIqStatus, intentIqTag) };

      monetizationManager.ppid = extractIiqId(intentIqTag.getIntentIqData());
    }

    monetizationManager.targeting = targeting;
    monetizationManager.addAdapter(new PrebidAdapter());
    monetizationManager.addAdapter(new AmazonAdapter(window.Site.apsPubID));
    monetizationManager.onSlotRendered = onSlotRendered;
    monetizationManager.enableGPT();
  });
}
