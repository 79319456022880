import BaseAdapter from './base-adapter';

window.pbjs = window.pbjs || { que: [] };

class PrebidAdapter extends BaseAdapter {
  constructor() {
    super();
    this.library = window.pbjs;
    this.userIdModules = window.Site.userIdModules;
    this.cachingPrebidUnits = window.Site.slots.filter((slot) => slot.cached);
  }

  init = () => {
    this.library.que.push(() => {
      this.library.bidderSettings = {
        sendStandardTargeting: false,

        adform: {
          bidCpmAdjustment: (bidCpm) => bidCpm * 1.01,
        },
      };

      this.library.setConfig({
        priceGranularity: `dense`,
        gptPreAuction: {
          useDefaultPreAuction: true,
        },
        rubicon: { singleRequest: true },
        userSync: {
          filterSettings: {
            iframe: {
              bidders: `*`,
              filter: `include`,
            },
          },
          userIds: this.userIdModules,
          auctionDelay: 50,
        },
        consentManagement: {
          gdpr: {
            cmpApi: `iab`,
            timeout: 8000,
            defaultGdprScope: true,
          },
          usp: {
            timeout: 1000,
          },
        },
        gvlMapping: {
          ogury: `31`,
        },
        useBidCache: true,
        bidCacheFilterFunction: (bid) =>
          this.cachingPrebidUnits.some(({ code }) => bid.adUnitCode.includes(code)),
      });

      this.library.setBidderConfig({
        bidders: [`oftmedia`],
        config: {
          schain: {
            validation: `relaxed`,
            config: {
              ver: `1.0`,
              complete: 1,
              nodes: [
                {
                  asi: `152media.info`,
                  sid: `152M19`,
                  hp: 1,
                },
              ],
            },
          },
        },
      });
    });
  };

  registerSlots = (adSlots) => {
    this.library.que.push(() => {
      this.library.addAdUnits(
        adSlots.map((adSlot) => adSlot.sharedConfig.prebidConfig).filter((adSlot) => adSlot),
      );
    });
  };

  fetch = (adSlots) => {
    if (adSlots.length) {
      this.library.que.push(() => {
        this.library.requestBids({
          adUnitCodes: adSlots.map((adSlot) => adSlot.id),
          bidsBackHandler: () => {
            this.library.setTargetingForGPTAsync(adSlots.map((adSlot) => adSlot.id));
            this.onLoad(adSlots, `prebid`);
          },
        });
      });
    }
  };
}

export default PrebidAdapter;
